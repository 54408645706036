<template>
    <svg
        width="21"
        height="12"
        viewBox="0 0 21 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10.3782 11.7209C10.1975 11.7218 10.0185 11.6865 9.85166 11.6173C9.6848 11.548 9.53346 11.4461 9.40651 11.3176L0.239845 2.15092C0.0570152 1.88344 -0.0254888 1.55999 0.00688301 1.23762C0.0392549 0.915247 0.184424 0.614661 0.416786 0.388875C0.649147 0.16309 0.953775 0.0266084 1.27694 0.00350268C1.60011 -0.0196031 1.92106 0.0721515 2.18318 0.262583L10.3782 8.40258L18.5732 0.262583C18.8316 0.095033 19.1388 0.0190945 19.4455 0.0469383C19.7522 0.074782 20.0407 0.204796 20.2648 0.416142C20.4888 0.627487 20.6354 0.907925 20.681 1.21251C20.7267 1.51709 20.6687 1.82818 20.5165 2.09592L11.3498 11.2626C11.2276 11.4011 11.0784 11.5132 10.9113 11.592C10.7442 11.6708 10.5628 11.7147 10.3782 11.7209Z"
        />
    </svg>
</template>
